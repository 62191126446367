import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import * as queryString from "query-string"

import firebase from "gatsby-plugin-firebase"
import { useAuth } from "gatsby-theme-firebase"

export default ({ location }) => {
  const [displayName, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [password2, setPassword2] = React.useState("")
  const [error, setError] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const { isLoggedIn } = useAuth()

  React.useEffect(() => {
    const { email, name } = queryString.parse(location.search)
    setName(name)
    setEmail(email)
    navigate(location.pathname, { replace: true })
  }, [])

  const migrateUser = async () => {
    setError("")
    if (password !== password2) return setError("Your passwords don't match.")
    try {
      setLoading(true)
      await firebase.functions().httpsCallable("migration")({
        email: email.toLowerCase(),
        password,
        displayName,
      })
      await firebase.auth().signInWithEmailAndPassword(email, password)
      setLoading(false)
      navigate("/education")
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }
  return (
    <Layout>
      <SEO title="Migrate From Another Platform to Millionaire Millennial" />
      {loading && (
        <div className="dark-overlay">
          <div className="spinner" />
        </div>
      )}
      <h1>Welcome to Millionaire Millennial!</h1>
      <h4>
        You can move over your old account quite easily - just put in your info and click migrate!
      </h4>
      <small>*Make sure to use the same email you purchased your course(s) with!*</small>

      <p>
        <label>
          <b>Email Address:</b>
          <br />
        </label>
        <input
          type="email"
          value={email}
          onChange={event => {
            setEmail(event.target.value)
          }}
        />
      </p>
      <p>
        <label>
          <b>Your Name:</b>
          <br />
        </label>
        <input
          value={displayName}
          onChange={event => {
            setName(event.target.value)
          }}
        />
      </p>
      <p>
        <label>
          <b>Password:</b>
          <br />
        </label>
        <input
          type="password"
          minLength="6"
          value={password}
          onChange={event => {
            setPassword(event.target.value)
          }}
        />
      </p>
      <p>
        <label>
          <b>Confirm Password:</b>
          <br />
        </label>
        <input
          type="password"
          minLength="6"
          value={password2}
          onChange={event => {
            setPassword2(event.target.value)
          }}
        />
      </p>
      <small style={{ color: "red" }}>{error}</small>
      <button onClick={() => migrateUser()}>Migrate Account & Sign In</button>
      <br />
      <center>
        <small>
          *From now on, you can login here on Millionaire Millennial to access your
          class/course*
        </small>
      </center>
    </Layout>
  )
}
